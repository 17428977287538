import React, { useState } from "react";
import { Helmet } from "react-helmet";
import faqs from "../../../utils/faq";
import styles from "./FAQ.module.css";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>FAQs - Frequently Asked Questions</title>
        <meta
          name="description"
          content="Find answers to the most common questions about our services, products, and more. Get quick help with our detailed FAQ section."
        />
        <meta
          name="keywords"
          content="FAQ, frequently asked questions, customer support, tech help, product inquiries, support guide"
        />
        <meta property="og:title" content="FAQs - Frequently Asked Questions" />
        <meta
          property="og:description"
          content="Find answers to the most common questions about our services, products, and more. Get quick help with our detailed FAQ section."
        />
        <meta
          property="og:keywords"
          content="FAQ, frequently asked questions, customer support, tech help, product inquiries, support guide"
        />
      </Helmet>

      <section className={styles.faqSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>FAQs</h2>
          <div className={styles.faqContainer}>
            {faqs.map((faq, index) => (
              <div key={index} className={styles.faqItem}>
                <div
                  className={styles.faqQuestion}
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                  <span className={styles.icon}>
                    {openIndex === index ? "-" : "+"}
                  </span>
                </div>
                {openIndex === index && (
                  <div className={styles.faqAnswer}>{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
