import React, { useState } from "react";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";
import axios from "axios";
import DOMPurify from "dompurify";
import { FaRegGrinStars } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoPaperPlaneOutline } from "react-icons/io5";
import Spinner from "../../common/Other/Spinner";
import ErrorMessage from "../../common/Message/ErrorMessage";
import termsAndConditions from "../../../assets/files/terms_and_conditions_pickly_2024.pdf";
import styles from "./Review.module.css";

const Review = ({ reportError, openAIAPI, webPilotAPI, errorStatus }) => {
  const [email, setEmail] = useState("");
  const [model, setModel] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [reportType, setReportType] = useState("basic");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [modelError, setModelError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!termsAccepted) {
      alert("You must accept the terms and conditions.");
      return;
    }

    let valid = true;
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (model.trim() === "") {
      setModelError("You must enter a device model (e.g., iPhone 16 Pro Max).");
      valid = false;
    } else {
      setModelError("");
    }

    if (!valid) {
      return;
    }

    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedModel = DOMPurify.sanitize(model);
    const sanitizedReportType = DOMPurify.sanitize(reportType);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payments/create-checkout-session`,
        {
          email: sanitizedEmail,
          model: sanitizedModel,
          termsAccepted,
          reportType: sanitizedReportType,
        }
      );

      if (response.status === 201) {
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.status);
      } else {
        setError("Error occurred. Please try again.");
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error while creating new review",
          component: "Review",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  };

  const isFormValid =
    email.trim() !== "" &&
    model.trim() !== "" &&
    termsAccepted &&
    !emailError &&
    !modelError;

  return (
    <div className={styles.box}>
      <Helmet>
        <title>Review and Recommendations Device</title>
        <meta
          name="description"
          content={
            "Check out reviews about different products and decide what you should choose."
          }
        />
        <meta
          name="keywords"
          content={
            "opinions, recommendations, product, analysis, artificial intelligence, device, electronics, TV, smartphone, washing machine, refrigerator, dishwasher"
          }
        />
        <meta
          property="og:title"
          content={"Review and Recommendations Device"}
        />
        <meta
          property="og:description"
          content={
            "Check out reviews about different products and decide what you should choose."
          }
        />
        <meta property="og:type" content="product" />
      </Helmet>
      <div className={`${styles.imagesBoxLeft} hide-on-mobile`}>
        <div className={`${styles.image} ${styles.imagee}`}></div>
        <div className={`${styles.image} ${styles.imagef}`}></div>
        <div className={`${styles.image} ${styles.imageg}`}></div>
        <div className={`${styles.image} ${styles.imageh}`}></div>
        {/* <div className={`${styles.image} ${styles.imagej}`}></div> */}
      </div>

      <div className={styles.container}>
        <h1 className={styles.header}>Get the Best Device for You</h1>
        <p className={styles.paragraph}>
          Get personalized advice on your next device! Enter the product name
          and your email to receive AI-driven insights.
          {/* Get personalized device recommendations tailored to your needs in just
          a few clicks – simply provide the model and your email.
          <br />
          Powered by AI, our expert insights will guide you towards the best
          decision. Access accurate, up-to-date information for only <br />
          <span className={styles.priceHighlight}>$3 </span> for a{" "}
          <span className={styles.priceHighlight}>basic report</span>
          {!webPilotAPI && <span>.</span>}{" "}
          {webPilotAPI && (
            <span>
              or <span className={styles.priceHighlight}>$5</span> for an{" "}
              <span className={styles.priceHighlight}>extended report.</span>
            </span>
          )}
          <br /> */}
        </p>

        <div className={styles.summary}>
          <p className={styles.summaryItem}>
            <span>
              <FaRegGrinStars />
            </span>
            4.9 user rating
          </p>
          <p className={styles.summaryItem}>
            <span>
              <FiUsers />
            </span>
            800+ users
          </p>
          <p className={styles.summaryItem}>
            <span>
              <IoPaperPlaneOutline />
            </span>
            2000+ recommendations
          </p>
        </div>

        {error && <ErrorMessage statusCode={error} message={error} />}

        {reportError && openAIAPI && !errorStatus ? (
          <form
            onSubmit={handleSubmit}
            className={`${styles.form} form-mobile`}
          >
            <div className={styles.inputGroup}>
              <label htmlFor="email" className={styles.label}>
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
                placeholder="Enter your email"
              />
              {emailError && <span className={styles.error}>{emailError}</span>}
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="model" className={styles.label}>
                Product name:
              </label>
              <input
                type="text"
                id="model"
                name="model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                required
                className={styles.input}
                placeholder="Enter device model"
              />
              <span className={styles.hint}>
                Enter the product name e.g. iPhone 16 Pro Max
              </span>
              {modelError && <span className={styles.error}>{modelError}</span>}
            </div>

            {webPilotAPI && (
              <div className={styles.inputGroup}>
                <label htmlFor="reportType" className={styles.label}>
                  Report type:
                </label>
                <select
                  id="reportType"
                  name="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  className={styles.input} // Używamy tej samej klasy co dla input
                >
                  <option value="basic">Basic report</option>
                  <option value="extended">Extended report</option>
                </select>
                <span className={styles.hint}>
                  Choose a basic report for $3 {webPilotAPI && (<span>or an extended report for $5 with
                  user sentiment analysis</span>)}
                </span>
              </div>
            )}

            <div className={styles.checkboxGroup}>
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className={styles.checkbox}
              />
              <label htmlFor="terms" className={styles.checkboxLabel}>
                I accept the{" "}
                <a
                  href={termsAndConditions}
                  download="terms_and_conditions_pickly_2024.pdf"
                >
                  terms and conditions.
                </a>
              </label>
            </div>
            <div>
              <button
                type="submit"
                className={`${styles.button} ${
                  !isFormValid ? styles.buttonDisabled : ""
                }`}
                disabled={!isFormValid}
              >
                {loading ? <Spinner /> : <span>Get Your Recommendation</span>}
              </button>
              <br />
              <div className="tax">
                *Prices exclude tax, which will be added at checkout. Payments
                are securely processed through Stripe.
              </div>
            </div>
          </form>
        ) : (
          <div className={styles.maintenance}>
            Thank you for visiting! Our website is currently undergoing
            maintenance to enhance your experience. We’ll be back online
            shortly. For urgent matters, please reach out to us at{" "}
            <b>office@picklynow.com</b>. We apologize for the inconvenience and
            appreciate your understanding!
          </div>
        )}
      </div>
      <div className={`${styles.imagesBoxRight} hide-on-mobile`}>
        <div className={`${styles.image} ${styles.imagea}`}></div>
        <div className={`${styles.image} ${styles.imageb}`}></div>
        <div className={`${styles.image} ${styles.imagec}`}></div>
        <div className={`${styles.image} ${styles.imaged}`}></div>
        {/* <div className={`${styles.image} ${styles.imagei}`}></div> */}
      </div>
    </div>
  );
};

export default Review;
