import React from "react";
import { Helmet } from "react-helmet";
import styles from "./HowItWorks.module.css";

const HowItWorks = ({ webPilotAPI }) => {
  return (
    <>
      <Helmet>
        <title>How It Works - Get Personalized Tech Recommendations</title>
        <meta
          name="description"
          content="Learn how our service works to help you make the best tech purchasing decisions. Get personalized reports and recommendations on gadgets and accessories."
        />
        <meta
          name="keywords"
          content="how it works, tech recommendations, personalized advice, gadget shopping, step by step guide, pricing"
        />
        <meta
          property="og:title"
          content="How It Works - Get Personalized Tech Recommendations"
        />
        <meta
          property="og:description"
          content="Learn how our service works to help you make the best tech purchasing decisions. Get personalized reports and recommendations on gadgets and accessories."
        />
        <meta
          property="og:keywords"
          content="how it works, tech recommendations, personalized advice, gadget shopping, step by step guide, pricing"
        />
      </Helmet>

      <section className={styles.howItWorksSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>How It Works</h2>
          <div className={styles.stepsContainer}>
            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <h3 className={styles.stepTitle}>
                Enter your email and device name
              </h3>
              <p className={styles.stepDescription}>
                Enter your email address and select the device you are
                considering, e.g. <i>iPhone 16 Pro Max</i>.
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <h3 className={styles.stepTitle}>
                {webPilotAPI && <span>Select the report </span>}and accept the
                terms
              </h3>
              <p className={styles.stepDescription}>
                {webPilotAPI && (
                  <span>Select the report type: basic or extended.</span>
                )}{" "}
                Then accept the terms and conditions.
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <h3 className={styles.stepTitle}>Get Your Recommendation</h3>
              <p className={styles.stepDescription}>
                Click “Get Your Recommendation" and pay $3 for a{" "}
                {webPilotAPI && <span>basic</span>} report
                {!webPilotAPI && <span>.</span>}{" "}
                {webPilotAPI && <span>or $5 for extended report.</span>}
              </p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepNumber}>4</div>
              <h3 className={styles.stepTitle}>What you’ll get</h3>
              <p className={styles.stepDescription}>
                Receive a tailored report with pros, cons, user reviews,
                alternatives, and more to help you decide.
              </p>
            </div>
          </div>
          <p className={styles.footerText}>
            Your $3 goes towards maintaining and improving our service. Fast,
            simple, and effective!
          </p>
          {/* <div className="tax-b">
            *The above price does not include tax. Tax will be automatically
            added to the base price during
            <br /> the transaction. Transactions are handled by Stripe, a global
            payment platform.
          </div> */}
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
